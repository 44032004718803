<div [ngClass]="{ 'bg-white min-h-[100vh] px-[98px] py-8 space-y-16': true }">
  <div class="flex flex-col justify-center items-center gap-3">
    <div class="flex justify-center items-center gap-10">
      <p class="text-[16px]">
        Paying of Revenues made easy with Pay Kaduna check out our Tax Trails
      </p>
      <button nz-button nzType="primary">Check out Now</button>
    </div>

    <p class="text-[50px] font-bold">
      Pay Kaduna <span class="text-pk-teal-500">News</span> Portal
    </p>

    <div class="flex gap-4 items-center justify-between">
      <div class="bg-[#ECFCFF] p-2 rounded-lg flex items-center gap-1">
        <svg-icon
          src="assets/svgs/weather.svg"
          [svgStyle]="{ 'width.px': 20 }"
        ></svg-icon>
        <p class="font-bold tracking-wide text-pk-teal-500">15&deg;C KADUNA</p>
      </div>

      <div class="p-2 flex items-center gap-1">
        <svg-icon
          src="assets/svgs/clock.svg"
          [svgStyle]="{ 'width.px': 20 }"
        ></svg-icon>
        <p class="font-semibold tracking-wide">Wednesday, 10 March 2023</p>
      </div>

      <div class="p-2 flex items-center gap-1">
        <svg-icon
          src="assets/svgs/facebook.svg"
          [svgStyle]="{ 'height.px': 25 }"
        ></svg-icon>
        <svg-icon
          src="assets/svgs/instagram.svg"
          [svgStyle]="{ 'height.px': 25 }"
        ></svg-icon>
        <svg-icon
          src="assets/svgs/youtube.svg"
          [svgStyle]="{ 'height.px': 25 }"
        ></svg-icon>
      </div>
    </div>
  </div>

  <div nz-row nzGutter="30">
    <div nz-col nzSpan="14" class="flex justify-end">
      <img
        nz-image
        nzSrc="assets/images/post/post-1-image.jpg"
        nzFallback="assets/images/post/fallback.png"
        width="92%"
      />
    </div>

    <div nz-col nzSpan="10" class="space-y-6">
      @for(post of posts; track $index) {
      <div class="flex gap-2 items-center">
        <img
          nz-image
          [nzSrc]="post.img"
          nzFallback="assets/images/post/fallback.png"
          width="300"
        />

        <div class="space-y-2">
          <p>{{ post.date }}</p>
          <p class="font-bold text-[16px]">{{ post.title }}</p>
          <p>{{ post.desc }}</p>
        </div>
      </div>
      }
    </div>
  </div>

  <div nz-row nzGutter="20">
    <p class="font-bold text-2xl cs-underline"><span>Don't Miss</span></p>

    <nz-divider></nz-divider>

    @for(post of posts2; track $index) {
    <div nz-col nzSpan="8" class="flex flex-col gap-2">
      <nz-card
        class="rounded-2xl bg-white shadow-[0px_0px_20px_0px_rgba(6,6,6,0.1)]"
      >
        <img
          nz-image
          [nzSrc]="post.img"
          nzFallback="assets/images/post/fallback.png"
          width="100%"
          style="object-fit: cover; height: 300px"
          class="rounded-xl"
        />

        <div class="space-y-2 mt-4">
          <p class="font-bold text-[16px] min-h-[50px]">{{ post.title }}</p>
          <p>{{ post.desc }}</p>
          <div class="flex justify-between items-center">
            <p>{{ post.date }}</p>
            <button nz-button nzType="link" class="font-semibold">
              Read More
            </button>
          </div>
        </div>
      </nz-card>
    </div>
    }
  </div>

  <div>
    <p class="font-bold text-[16px]">Latest Update</p>

    <nz-divider></nz-divider>

    <div>
      <img
        nz-image
        nzSrc="assets/images/post/post-8.png"
        nzFallback="assets/images/post/fallback.png"
        width="100%"
        style="object-fit: cover"
        class="rounded-xl"
      />
    </div>
  </div>
</div>
