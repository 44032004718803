<div
  [ngClass]="{
    'px-12 2xl:px-[98px] mx-auto flex-1 flex justify-center w-full': true
  }"
>
  <!-- Body Content -->
  <div class="flex flex-col justify-center items-center w-full pb-[4rem]">
    <!-- Description -->
    <div class="max-w-[630px] mb-10 mt-5">
      <p class="text-2xl text-center text-white">
        “Taxes, after all, are dues that we pay for the privileges of membership
        in an organized society”
      </p>

      <p class="text-2xl text-center text-white">-Franklin D. Roosevelt</p>
    </div>

    <!-- Verification input -->
    <div
      class="w-full overflow-hidden bg-white rounded-2xl max-w-[671px] flex py-[9px] px-3 items-center gap-5"
    >
      <app-general-input
        [className]="'border-none outline-none'"
        [formGroup]="verificationForm"
        controlName="verification"
        type="text"
        placeholder=" Verify Invoice, eTCC and Receipt"
      ></app-general-input>
      <button
        appGeneralButton
        [ngClass]="{ 'py-4 px-[30px] max-w-[106px]': true }"
      >
        verify
      </button>
    </div>

    <div class="flex flex-col w-full gap-4">
      <!-- Icon card links -->
      <ul class="flex flex-row w-full list-none max-w-[1318px] mx-auto">
        <li class="cursor-pointer">
          <button
            type="button"
            class="rounded-2xl bg-white flex-1 shrink-0 flex flex-col transition hover:-translate-y-[64px] duration-500 [&:hover>div]:transition-all [&:hover>div]:-translate-y-[20px] [&:hover>div]:duration-300 [&:hover>div]:delay-200 [&:hover>div]:opacity-100 max-w-[174px] min-w-[174px] min-h-[167px] justify-center items-center [&>*]:cursor-pointer cursor-pointer"
            (click)="openModalHandler($event)"
            [attr.data-id]="2"
            [attr.data-slug]="'motor-vehicle-administration'"
          >
            <svg-icon
              src="assets/svgs/mva-icon.svg"
              [svgStyle]="{ 'width.px': 68, 'height.px': 68 }"
            >
            </svg-icon>
            <span class="text-base font-medium capitalize"
              >Motor Vehicle Administration</span
            >
          </button>
        </li>

        <li class="flex items-end justify-center flex-1 space-x-5">
          <button
            class="flex flex-col items-center justify-center w-32 h-32 space-y-1 rounded-full hover:bg-opacity-15 hover:bg-white [&>*]:cursor-pointer cursor-pointer"
            (click)="openModalHandler($event)"
            [attr.data-id]="12"
            [attr.data-slug]="'court-fees'"
          >
            <svg-icon
              src="assets/svgs/court-fee-icon.svg"
              [svgStyle]="{ 'width.px': 30, color: 'white' }"
            >
            </svg-icon>
            <p class="text-[11px] text-white">Court Fees</p>
          </button>

          <button
            class="flex flex-col items-center justify-center w-32 h-32 space-y-1 rounded-full hover:bg-opacity-15 hover:bg-white [&>*]:cursor-pointer cursor-pointer"
            (click)="openModalHandler($event)"
            [attr.data-id]="13"
            [attr.data-slug]="'tertiary-education'"
          >
            <svg-icon
              src="assets/svgs/tertiary-education-icon.svg"
              [svgStyle]="{ 'width.px': 30, color: 'white' }"
            >
            </svg-icon>
            <p class="text-[11px] text-white">Tertiary Education</p>
          </button>

          <button
            class="flex flex-col items-center justify-center w-32 h-32 space-y-1 rounded-full hover:bg-opacity-15 hover:bg-white [&>*]:cursor-pointer cursor-pointer"
            (click)="openModalHandler($event)"
            [attr.data-id]="11"
            [attr.data-slug]="'stamp-duty-tax'"
          >
            <svg-icon
              src="assets/svgs/stamp-duty-icon.svg"
              [svgStyle]="{ 'width.px': 30, color: 'white' }"
            >
            </svg-icon>
            <p class="text-[11px] text-white">Stamp Duty</p>
          </button>
          <button
            class="flex flex-col items-center justify-center w-32 h-32 space-y-1 rounded-full hover:bg-opacity-15 hover:bg-white [&>*]:cursor-pointer cursor-pointer"
            (click)="openModalHandler($event)"
            [attr.data-id]="15"
            [attr.data-slug]="'transport'"
          >
            <svg-icon
              src="assets/svgs/transport-icon.svg"
              [svgStyle]="{ 'width.px': 30, color: 'white' }"
            >
            </svg-icon>
            <p class="text-[11px] text-white">Transport</p>
          </button>
          <button
            class="flex flex-col items-center justify-center w-32 h-32 space-y-1 rounded-full hover:bg-opacity-15 hover:bg-white [&>*]:cursor-pointer cursor-pointer"
            (click)="openModalHandler($event)"
            [attr.data-id]="14"
            [attr.data-slug]="'lga-collections'"
          >
            <svg-icon
              src="assets/svgs/lga-collection-icon.svg"
              [svgStyle]="{ 'width.px': 30, color: 'white' }"
            >
            </svg-icon>
            <p class="text-[11px] text-white">LGA Collections</p>
          </button>
        </li>

        <li
          class="rounded-2xl bg-white flex-1 shrink-0 flex flex-col items-center transition hover:-translate-y-[64px] duration-500 [&:hover>div]:transition-all [&:hover>div]:-translate-y-[20px] [&:hover>div]:duration-300 [&:hover>div]:delay-200 [&:hover>div]:opacity-100 max-w-[174px] min-h-[167px]"
        >
          <button
            type="button"
            class="flex flex-col items-center flex-1 w-full gap-1 pt-6 px-[10px] [&>*]:cursor-pointer cursor-pointer"
            (click)="openModalHandler($event)"
            [attr.data-id]="10"
            [attr.data-slug]="'hotels-restaurants-event-centres'"
          >
            <svg-icon
              src="assets/svgs/hotel-icon.svg"
              [svgStyle]="{ 'width.px': 68, 'height.px': 68 }"
            >
            </svg-icon>
            <span class="text-base font-medium"
              >Hotels, Restaurants & Event centers</span
            >
          </button>
        </li>
      </ul>

      <!-- Icon card links 2nd row -->
      <ul
        class="flex flex-row justify-center w-full gap-4 list-none mb-[100px] max-w-[1318px] mx-auto"
      >
        <li
          class="rounded-2xl bg-white flex-1 shrink-0 flex flex-col items-center transition hover:-translate-y-[64px] duration-500 [&:hover>div]:transition-all [&:hover>div]:-translate-y-[20px] [&:hover>div]:duration-300 [&:hover>div]:delay-200 [&:hover>div]:opacity-100 min-h-[167px]"
        >
          <button
            type="button"
            class="flex flex-col items-center flex-1 w-full gap-1 pt-6 px-[10px] [&>*]:cursor-pointer cursor-pointer"
            (click)="openModalHandler($event)"
            [attr.data-id]="5"
            [attr.data-slug]="'paye-direct-assessment'"
          >
            <svg-icon
              src="assets/svgs/paye-manager-icon.svg"
              [svgStyle]="{ 'width.px': 68, 'height.px': 68 }"
            >
            </svg-icon>
            <span class="text-base font-medium">PAYE & Direct Assessment</span>
          </button>
        </li>
        <li
          class="rounded-2xl bg-white flex-1 shrink-0 flex flex-col items-center transition hover:-translate-y-[64px] duration-500 [&:hover>div]:transition-all [&:hover>div]:-translate-y-[20px] [&:hover>div]:duration-300 [&:hover>div]:delay-200 [&:hover>div]:opacity-100 min-h-[167px]"
        >
          <button
            type="button"
            class="flex flex-col items-center flex-1 w-full gap-1 pt-6 px-[10px] [&>*]:cursor-pointer cursor-pointer"
            (click)="openModalHandler($event)"
            [attr.data-id]="3"
            [attr.data-slug]="'betting-pools-lottery'"
          >
            <svg-icon
              src="assets/svgs/betting-icon.svg"
              [svgStyle]="{ 'width.px': 68, 'height.px': 68 }"
            >
            </svg-icon>
            <span class="text-base font-medium">Betting, Pools & Lottery</span>
          </button>
        </li>
        <li
          class="rounded-2xl bg-white flex-1 shrink-0 flex flex-col items-center transition hover:-translate-y-[64px] duration-500 [&:hover>div]:transition-all [&:hover>div]:-translate-y-[20px] [&:hover>div]:duration-300 [&:hover>div]:delay-200 [&:hover>div]:opacity-100 min-h-[167px]"
        >
          <button
            type="button"
            class="flex flex-col items-center flex-1 w-full gap-1 pt-6 px-[10px] [&>*]:cursor-pointer cursor-pointer"
            (click)="openModalHandler($event)"
            [attr.data-id]="4"
            [attr.data-slug]="'environment-natural-resources'"
          >
            <svg-icon
              src="assets/svgs/mining-icon.svg"
              [svgStyle]="{ 'width.px': 68, 'height.px': 68 }"
            >
            </svg-icon>
            <span class="text-base font-medium"
              >Environment & Natural resources</span
            >
          </button>
        </li>
        <li
          class="rounded-2xl bg-white flex-1 shrink-0 flex flex-col items-center transition hover:-translate-y-[64px] duration-500 [&:hover>div]:transition-all [&:hover>div]:-translate-y-[20px] [&:hover>div]:duration-300 [&:hover>div]:delay-200 [&:hover>div]:opacity-100 min-h-[167px]"
        >
          <button
            type="button"
            class="flex flex-col items-center flex-1 w-full gap-1 pt-6 px-[10px] [&>*]:cursor-pointer cursor-pointer"
            (click)="openModalHandler($event)"
            [attr.data-id]="6"
            [attr.data-slug]="'mda-collection'"
          >
            <svg-icon
              src="assets/svgs/mda-collection-icon.svg"
              [svgStyle]="{ 'width.px': 68, 'height.px': 68 }"
            >
            </svg-icon>
            <span class="text-base font-medium">MDA Collection</span>
          </button>
        </li>
        <li
          class="rounded-2xl bg-white flex-1 shrink-0 flex flex-col items-center transition hover:-translate-y-[64px] duration-500 [&:hover>div]:transition-all [&:hover>div]:-translate-y-[20px] [&:hover>div]:duration-300 [&:hover>div]:delay-200 [&:hover>div]:opacity-100 min-h-[167px]"
        >
          <button
            type="button"
            class="flex flex-col items-center flex-1 w-full gap-1 pt-6 px-[10px] [&>*]:cursor-pointer cursor-pointer"
            (click)="openModalHandler($event)"
            [attr.data-id]="7"
            [attr.data-slug]="'development-levies'"
          >
            <svg-icon
              src="assets/svgs/development-levy-icon.svg"
              [svgStyle]="{ 'width.px': 68, 'height.px': 68 }"
            >
            </svg-icon>
            <span class="text-base font-medium"
              >Development Levies, Fees & Others</span
            >
          </button>
        </li>

        <li
          class="rounded-2xl bg-white flex-1 shrink-0 flex flex-col items-center transition hover:-translate-y-[64px] duration-500 [&:hover>div]:transition-all [&:hover>div]:-translate-y-[20px] [&:hover>div]:duration-300 [&:hover>div]:delay-200 [&:hover>div]:opacity-100 min-h-[167px]"
        >
          <button
            type="button"
            class="flex flex-col items-center flex-1 w-full gap-1 pt-6 px-[10px] [&>*]:cursor-pointer cursor-pointer"
            (click)="openModalHandler($event)"
            [attr.data-id]="8"
            [attr.data-slug]="'education-fees-licensing'"
          >
            <svg-icon
              src="assets/svgs/education-icon.svg"
              [svgStyle]="{ 'width.px': 68, 'height.px': 68 }"
            >
            </svg-icon>
            <span class="text-base font-medium"
              >Education (Fees and licensing)</span
            >
          </button>
        </li>

        <li
          class="rounded-2xl bg-white flex-1 shrink-0 flex flex-col items-center transition hover:-translate-y-[64px] duration-500 [&:hover>div]:transition-all [&:hover>div]:-translate-y-[20px] [&:hover>div]:duration-300 [&:hover>div]:delay-200 [&:hover>div]:opacity-100 min-h-[167px]"
        >
          <button
            type="button"
            class="flex flex-col items-center flex-1 w-full gap-1 pt-6 px-[10px] [&>*]:cursor-pointer cursor-pointer"
            (click)="openModalHandler($event)"
            [attr.data-id]="9"
            [attr.data-slug]="'withholding-tax'"
          >
            <svg-icon
              src="assets/svgs/witholding-tax-icon.svg"
              [svgStyle]="{ 'width.px': 68, 'height.px': 68 }"
            >
            </svg-icon>
            <span class="text-base font-medium">Witholding Tax</span>
          </button>
        </li>
      </ul>
    </div>
  </div>

  <ng-container *ngTemplateOutlet="modalTemplate; context: {}"></ng-container>
  <ng-container
    *ngTemplateOutlet="modalTemplateTwo; context: {}"
  ></ng-container>
  @if (slug()==='mda-collection') {
  <app-invoice-modal></app-invoice-modal>
  }
</div>

<app-home-footer></app-home-footer>

<ng-template #modalTemplate>
  <app-modal (closeModalEvent)="clickOut($event)">
    <div class="w-[697px] min-h-[526px] bg-white rounded-[20px] py-7">
      <div
        [ngClass]="{
          'w-full mx-auto': true,
          'max-w-[438px]': modalSection === 1
        }"
      >
        <div [ngClass]="{ 'flex items-center justify-center': true }">
          <span [ngClass]="{ 'p-[10px] rounded-[100px] bg-[#F4FFF8]': true }">
            <svg-icon
              [src]="selectedSystem()?.topIcon"
              [svgStyle]="{ 'width.px': 68, 'height.px': 68 }"
            >
            </svg-icon>
          </span>
        </div>
        <div>
          <p
            [ngClass]="{
              'text-center pt-4 text-xl font-bold text-tw-text-color': true
            }"
          >
            {{ selectedSystem()?.topHeader }}
          </p>
        </div>

        <ng-container *ngTemplateOutlet="modalSectionOne"></ng-container>
        <ng-container *ngTemplateOutlet="modalSectionTwo"></ng-container>
      </div>
    </div>
  </app-modal>
</ng-template>

<ng-template #modalSectionOne>
  <div
    class="basic-form"
    [ngClass]="{ 'pt-[38px]': true, hidden: modalSection === 2 }"
  >
    <p
      [ngClass]="{
        'text-center text-tw-text-color text-sm font-medium pb-2': true
      }"
    >
      Input your TPUI (Tax Payers Unique Index)
    </p>
    <div
      [ngClass]="{
        'bg-[#F4F4F4] w-full flex border border-transparent rounded-[10px] p-[2px]': true
      }"
    >
      <input
        type="text"
        [ngClass]="{
          'flex-1 py-4 px-3 bg-transparent outline-none text-tw-text-color text-sm': true
        }"
        placeholder="TPUI (tax payers unique Index)"
      />
      <button
        [ngClass]="{
          'w-[66px] bg-tw-primary rounded-lg flex justify-center items-center': true
        }"
      >
        <svg-icon
          src="assets/svgs/arrow_forward.svg"
          [svgStyle]="{ 'width.px': 14, 'height.px': 22 }"
        >
        </svg-icon>
      </button>
    </div>
    <p [ngClass]="{ 'pt-[65px] text-sm text-tw-text-color text-center': true }">
      Don&apos;t have an TPUI?
    </p>
    <div [ngClass]="{ 'text-center pt-4': true }">
      <button
        type="button"
        [ngClass]="{
          'rounded-lg bg-[#008733] text-white py-4 text-base capitalize w-full max-w-[208px] mx-auto': true
        }"
        (click)="openModalSectionTwo()"
      >
        register now
      </button>
    </div>
    <div>
      <p [ngClass]="{ 'pt-4 text-center text-tw-text-color text-sm': true }">
        "Please note that this registration is a one-time process; your TPUI
        Number will serve as the access credential for other tax payment
        services."
      </p>
    </div>
  </div>
</ng-template>

<ng-template #modalSectionTwo>
  <div [ngClass]="{ hidden: modalSection === 1 }">
    <div>
      <p
        [ngClass]="{
          'pt-8 pb-4 text-center text-base text-tw-text-color': true
        }"
      >
        Select preferred category
      </p>
    </div>
    <ul [ngClass]="{ 'flex gap-2 items-center justify-center': true }">
      @for (option of selectedSystem()?.config||[]; track $index) {
      <li>
        <a
          [ngClass]="{
            'p-2 rounded-tl-2xl rounded-tr-2xl flex flex-col gap-4 bg-[#DEDEDE33] h-[135px] w-[132px] items-center justify-center border border-transparent': true
          }"
          [routerLink]="[option.path]"
          routerLinkActive="bg-slate-200"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <svg-icon
            [src]="option.iconPath"
            [svgStyle]="{ 'width.px': 68, 'height.px': 68 }"
          >
          </svg-icon>
          <span [ngClass]="{ 'text-sm text-tw-text-color': true }">{{
            option.text
          }}</span>
        </a>
      </li>
      }
    </ul>
  </div>
</ng-template>

<ng-template #modalTemplateTwo>
  <app-modal-v1 (closeModalEvent)="clickOut($event)">
    <div
      [ngClass]="{
        'w-[452px] min-h-[302px] bg-white rounded-[20px] py-[37px] px-[28px] hidden flex-col': true,
        '!flex': modalToDisplay === 'revenueHeadModal'
      }"
    >
      <h3 [ngClass]="{ 'text-[#717272] font-bold text-xl': true }">
        Select Revenue Head
      </h3>
      <div [ngClass]="{ 'pt-[35px]': true }">
        <label for="" [ngClass]="{ 'text-sm text-[#717272]': true }"
          >Select Revenue Head</label
        >
        <nz-select
          nzSize="large"
          [nzLoading]="isLoading()"
          [ngClass]="{ 'w-full': true }"
          [nzPlaceHolder]="'Select'"
          [(ngModel)]="selectedRevenueHead"
        >
          @for (head of revenueHeadsQueryResponse.data() || []; track head.id) {
          <nz-option [nzValue]="head" [nzLabel]="head.revenueName"></nz-option>
          }
        </nz-select>
      </div>
      <div [ngClass]="{ 'mt-auto': true }">
        <button appGeneralButton (click)="redirectHandler()">continue</button>
      </div>
    </div>
    <div
      [ngClass]="{
        'hidden flex-col': true,
        '!flex': modalToDisplay === 'comingSoonModal'
      }"
    >
      <ng-container *ngTemplateOutlet="coming_soon; context: {}"></ng-container>
    </div>
  </app-modal-v1>
</ng-template>

<ng-template #coming_soon>
  <div
    [ngClass]="{
      'w-[691px] max-w-[90vw] h-[540px] max-h-[90vh] bg-white border border-[#f1f1f1] rounded-2xl flex items-center justify-center relative': true
    }"
  >
    <button
      type="button"
      [ngClass]="{ 'absolute top-[24px] right-[24px]': true }"
      (click)="closeComingSoon()"
    >
      <svg-icon
        src="assets/svgs/cancel.svg"
        [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
      >
      </svg-icon>
    </button>
    <div [ngClass]="{ 'flex flex-col gap-12': true }">
      <img
        [src]="'/assets/images/under_construction_img_compressed.jpg'"
        loading="lazy"
      />
      <div [ngClass]="{ 'text-center flex flex-col gap-2 items-center': true }">
        <p [ngClass]="{ 'text-[#292929] font-semibold text-2xl': true }">
          Coming Soon
        </p>
        <p [ngClass]="{ 'text-[#000000b2] text-sm': true }">
          Please check back later...
        </p>
      </div>
    </div>
  </div>
</ng-template>
