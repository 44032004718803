import {
  ChangeDetectorRef,
  Component,
  computed,
  inject,
  signal,
  ViewChild,
} from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { GeneralInputComponent } from '../../../shared/widgets/inputs/general-input/general-input.component';
import { FooterComponent } from './Footer/footer.component';
import { ModalComponent } from '../../../shared/widgets/modal/modal.component';
import { AuthService } from '../../../core/services/auth.service';
import { GeneralButtonDirective } from '../../../shared/directives/buttons/general-button.directive';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { ModalV1Component } from '../../../shared/widgets/modal-v1/modal-v1.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { sectorsList } from '../../../core/utils/revenueHeadStaticData';
import { NgApexchartsModule } from 'ng-apexcharts';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { LookupService } from '../../../core/services/lookup.service';
import { InvoiceModal } from '../../../shared/invoice-modal/invoice-modal.component';
import { setStateAction } from '../../../core/store/actions/invoice.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store';
import { errorToastAction } from '../../../core/store/actions/toast.actions';

type ExpertSystemTypes =
  | 'mva'
  | 'mda'
  | 'gaming'
  | 'mining'
  | 'paye'
  | 'devLevy'
  | 'education'
  | 'withHoldTax'
  | 'consumptionTax'
  | 'premises'
  | 'hospital'
  | 'transport';

type ExpertSystemProps = {
  [F in ExpertSystemTypes]: {
    topIcon: string;
    topHeader: string;
    // iconList?: { text: string; icon: string }[];
    formOptions: ('individual' | 'corporate' | 'state' | 'federal')[];
  };
};

const accountTypes = {
  individual: {
    id: 1,
    iconPath: 'assets/svgs/expert-modal/individual.svg',
    text: 'Individual',
  },
  corporate: {
    id: 2,
    text: 'Corporate',
    iconPath: 'assets/svgs/expert-modal/corporate.svg',
  },
  state: {
    id: 3,
    text: 'State Agency',
    iconPath: 'assets/svgs/expert-modal/state_agency.svg',
  },
  federal: {
    id: 4,
    text: 'Federal Agency',
    iconPath: 'assets/svgs/expert-modal/federal_agency.svg',
  },
};

const expertSystemList: ExpertSystemProps = {
  mva: {
    topIcon: 'assets/svgs/expert-modal/mva-top-icon.svg',
    topHeader: 'MVA',
    formOptions: ['individual', 'corporate', 'state', 'federal'],
  },
  paye: {
    topIcon: 'assets/svgs/expert-modal/paye-top-icon.svg',
    topHeader: 'PAYE Manager',
    formOptions: ['corporate'],
  },
  education: {
    topIcon: 'assets/svgs/expert-modal/education-top-icon.svg',
    topHeader: 'Education',
    formOptions: ['individual', 'corporate'],
  },
  mda: {
    topIcon: 'assets/svgs/expert-modal/mda-top-icon.svg',
    topHeader: 'Stamp Duty & Capital Gains Tax',
    formOptions: ['individual', 'corporate'],
  },
  mining: {
    topIcon: 'assets/svgs/expert-modal/mining-top-icon.svg',
    topHeader: 'Mining',
    formOptions: ['individual', 'corporate'],
  },
  gaming: {
    topIcon: 'assets/svgs/expert-modal/gaming-top-icon.svg',
    topHeader: 'Gaming',
    formOptions: ['individual', 'corporate'],
  },
  premises: {
    topIcon: 'assets/svgs/expert-modal/premises-top-icon.svg',
    topHeader: 'Business Premises',
    formOptions: ['individual', 'corporate'],
  },
  hospital: {
    topIcon: 'assets/svgs/expert-modal/hospital-top-icon.svg',
    topHeader: 'Hospital',
    formOptions: ['individual', 'corporate'],
  },
  transport: {
    topIcon: 'assets/svgs/expert-modal/transport-top-icon.svg',
    topHeader: 'Transport',
    formOptions: ['individual', 'corporate'],
  },
  consumptionTax: {
    topIcon: 'assets/svgs/expert-modal/consumption-tax-top-icon.svg',
    topHeader: 'Consumption Tax',
    formOptions: ['individual', 'corporate'],
  },
  devLevy: {
    topIcon: 'assets/svgs/expert-modal/devt-levy-top-icon.svg',
    topHeader: 'Development Levy',
    formOptions: ['individual', 'corporate'],
  },
  withHoldTax: {
    topIcon: 'assets/svgs/expert-modal/withHoldTax-top-icon.svg',
    topHeader: 'Witholding Tax',
    formOptions: ['individual', 'corporate', 'state', 'federal'],
  },
};

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    SvgIconComponent,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    GeneralInputComponent,
    FooterComponent,
    ModalComponent,
    GeneralButtonDirective,
    NzModalModule,
    ModalV1Component,
    NzSelectModule,
    GeneralButtonDirective,
    FormsModule,
    NgApexchartsModule,
    NgOptimizedImage,
    InvoiceModal,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
  host: {
    class: 'flex-1 flex item-end justify-center',
  },
})
export class HomeComponent {
  authService = inject(AuthService);
  lookupService = inject(LookupService);
  router = inject(Router);
  cd = inject(ChangeDetectorRef);
  store = inject(Store<AppState>);
  verificationForm = new FormGroup({
    verification: new FormControl(null),
  });
  activeModal = signal<string | null>(null);
  modalSection: 1 | 2 = 1;
  isVisible = true;
  selectedRevenueHead = null;
  modalToDisplay: 'revenueHeadModal' | 'comingSoonModal' = 'revenueHeadModal';
  expertSystems = signal(expertSystemList);
  revenueList = signal([]);
  slug = signal('');
  accountOptionsConfig = signal(accountTypes);

  @ViewChild(ModalComponent) modal: ModalComponent;
  @ViewChild(ModalV1Component) modalTwo: ModalV1Component;

  selectedSystem = computed(() => {
    if (!this.activeModal()) {
      return null;
    }

    const system = this.expertSystems()[this.activeModal()];
    system.config = [];
    system.formOptions.map((option) => {
      const config = this.accountOptionsConfig()[option];
      system.config.push({
        ...config,
        path: `/auth/register/${config.id}`,
      });
    });

    return system;
  });

  revenueHeadsQueryResponse = injectQuery(() => {
    return {
      queryKey: ['revenue-heads-by-slug', this.slug()],
      queryFn: ({ queryKey }) => {
        const [, slug] = queryKey;
        return this.lookupService.getRevenueHeadsByCategorySlug({
          categorySlug: slug,
        });
      },
      enabled: !!this.slug(),
    };
  });

  isLoading = computed(() => {
    return (
      this.revenueHeadsQueryResponse.isLoading() &&
      this.revenueHeadsQueryResponse.fetchStatus() === 'fetching'
    );
  });

  openNotification() {
    // this.store.dispatch(
    //     autohide: true,
    //     delay: 7000,
    //     placement: 'top-end',
    //     message: "Creating learner's permit bill information. Please wait...",
    //   })
    // );
  }

  openModalHandler(event: Event) {
    const target = event.currentTarget as HTMLButtonElement;
    const userRole = this.authService.userRole;
    const { slug, id } = target.dataset || {};

    if (slug === 'mda-collection') {
      this.slug.set(slug);
      if (userRole === 'SuperAdmin') {
        this.store.dispatch(
          errorToastAction({
            autohide: true,
            delay: 7000,
            placement: 'top-end',
            message: 'You do not have the required privileges for this action',
          })
        );
        return;
      }
      if (!this.authService.isLoggedIn) {
        this.router.navigate([''], {
          //  relativeTo: this.route,
          queryParams: {
            a: 'login',
            routeTo: 'mda-collections',
          },
        });
        return;
      }
      this.store.dispatch(setStateAction({ modalState: true }));
      return;
    }
    const selectedSector = (sectorsList || []).find(
      (sector) => sector.id === parseInt(id)
    );
    if (!selectedSector?.openDialogue && !selectedSector?.redirectUrl) {
      this.modalToDisplay = 'comingSoonModal';
      this.modalTwo.openModal();
      return;
    }
    if (selectedSector?.openDialogue) {
      this.slug.set(slug);
      this.modalTwo.openModal();
      return;
    }

    window.location.href = (selectedSector as any)?.redirectUrl || '';
  }

  redirectHandler(): void {
    if (!this.selectedRevenueHead?.expertSystem?.redirectUrl) {
      this.modalToDisplay = 'comingSoonModal';
      this.modalTwo.openModal();
      return;
    }
    window.location.href =
      this.selectedRevenueHead?.expertSystem?.redirectUrl || '';
  }

  closeComingSoon(): void {
    this.modalTwo.closeModal();
  }

  clickOut(closeModal: boolean): void {
    if (closeModal) {
      this.activeModal.set(null);
      this.modalSection = 1;
      this.modalToDisplay = 'revenueHeadModal';
      // this.revenueList.set([]);
      this.selectedRevenueHead = null;
    }
  }
  openModalSectionTwo() {
    this.modalSection = 2;
  }
}

//https://stackoverflow.com/questions/59720829/angular-component-not-updating-when-input-changes-value
