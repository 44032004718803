import { Component, inject } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';

@Component({
  selector: 'app-news',
  standalone: true,
  imports: [
    SvgIconComponent,
    CommonModule,
    RouterModule,
    NzButtonModule,
    NzGridModule,
    NzImageModule,
    NzCardModule,
    NzDividerModule,
  ],
  templateUrl: './news.component.html',
  styleUrl: './news.component.css',
})
export class NewsComponent {
  posts = [
    {
      img: 'assets/images/post/post-2.jpg',
      title: 'Penn’s expanding political climate gears up for the state',
      desc: 'The Education Sub-Committee of Infrastructure Development Council Monitoring',
      date: '27 Dec 2020',
    },
    {
      img: 'assets/images/post/post-3.jpg',
      title: 'Education Monitoring',
      desc: 'The Education Sub-Committee of Infrastructure Development Council Monitoring',
      date: '27 Dec 2020',
    },
    {
      img: 'assets/images/post/post-4.jpg',
      title: 'Stakeholders Meeting',
      desc: 'The Education Sub-Committee of Infrastructure Development Council Monitoring',
      date: '27 Dec 2020',
    },
  ];

  posts2 = [
    {
      img: 'assets/images/post/post-6.jpg',
      title: 'Now Is the Time to Think About the Development of the Road',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus lobortis augue condimentum maecenas. Metus at in fames vitae posuere ut vel....',
      date: 'Aug 2023',
    },
    {
      img: 'assets/images/post/post-5.jpg',
      title: 'Education Monitoring',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus lobortis augue condimentum maecenas. Metus at in fames vitae posuere ut vel....',
      date: 'Aug 2023',
    },
    {
      img: 'assets/images/post/post-7.jpg',
      title: 'Stakeholders Meeting',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus lobortis augue condimentum maecenas. Metus at in fames vitae posuere ut vel....',
      date: 'Aug 2023',
    },
  ];
}
